.fourofour{
    padding: 100px 0px;
    background: url(../../assets/solutions-bg.jpg) !important;

}


.fourofourHeading{
    font-size: 100px;
    color: #333;
    text-align: center;
    margin-bottom: 0px;
}
.fourofourtext{
    color: #333;
    text-align: center;
}

.fourofourLink{
    padding: 10px 15px;
    color: #333;
    text-align: center;
    border: 2px solid #333;
    text-align: center;
    display: block;
    width: 150px;
    margin: auto;
    margin-top: 40px;
   
}
.fourofourLink:hover{
    color: #333;
 
}