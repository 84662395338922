.servicePages{
    background: url(../../assets/solutions-bg.jpg) !important;
    padding: 150px 0px;
    p{
        font-family: 'Michroma', sans-serif;
        font-weight: bold;
    }
    h2{
        color: #0FE2FF;
        font-size: 52px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    button{
        margin-top: 25px;
        background-color: #00C1DB;
        color: #FFF;
        border:none;
        padding:10px 25px;
        text-transform: uppercase;
        border:2px solid #00C1DB;
        font-weight: 500;
        &:hover{
            background-color: #FFF;
            color: #00C1DB;
        }
    }
}

@media screen and (max-width: 600px) { 
    .servicePages{
        padding:  50px 0px !important; 

        h2{
             font-size: 28px !important;
          }
          p{
            font-size: 13px;
          }

          button{
            margin-bottom: 25px !important;
          }
    }
}
