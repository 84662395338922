.gameBox{background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%) !important;}
.gameMenu{padding-bottom: 20px;}
.dropdownFilter{width: fit-content;
    border: 1px solid #FFF !important;
    margin-left: 15px; }
.gameMenu{ background: #38383888; padding: 15px; border-radius: 5px; display: none !important}
.ListCard{position: relative; width: 100%; display: block;}
.labelName{color: #FFF;}
@media only screen and (max-width : 460px) {
    .gameBox{ padding:30px 15px !important; background: radial-gradient(361.73% 1644.58% at 100% 100%, rgba(20, 20, 20, 0.9) 0%, rgba(26, 26, 26, 0.9) 45.16%, rgba(51, 51, 51, 0.9) 100%); }
    .gamegridBox {grid-template-columns: 1fr !important; }
    .dropdownFilter{ width: 100%; margin-left: 0px !important   ;}
}
@media only screen and (max-width : 1025px) {
    .gamegridBox { grid-template-columns: 1fr 1fr 1fr !important; }
}
@media only screen and (max-width : 600px) {
    .gamegridBox { grid-template-columns: 1fr  !important;}
    .gameMenuHeading{ font-size: 20px !important; }
}
.gameMenuGrid{
    display: grid;
    grid-template-columns: 300px 300px;
    grid-gap: 10px;
    margin-top: -8px;
}


@media only screen and (max-width : 900px) { .gameMenuGrid{grid-template-columns: 0.8fr 1fr; }}
.listGridIcon{ display: inline-block; margin-left: 20px; cursor: pointer; }
.listGridIcon img{ filter: invert(100%) sepia(93%) saturate(27%) hue-rotate(39deg) brightness(108%) contrast(106%);}
.dropdownFilter{ background: transparent; border:1px solid var(--color-three); padding:10px; border-radius: 5px; color: #FFF; font-size: 17px; }
.dropdownFilter option{color: #333; }
.gameMenu{ margin-bottom: 50px; display: grid; align-items: end; grid-template-columns: 1fr 90px; box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.75);}
.gameMenu label{color: #FFF; font-size: 13px; margin-bottom: 10px; display: block;}
.trendinggameBg .labelName{display: none;}
.gamegridBox{background: #38383888; border-radius: 15px; padding: 30px; box-shadow: 1px 2px 5px 0px rgba(0,0,0,0.75);}
@media only screen and (max-width : 600px) {.gamegridBox{background: transparent; padding:0px;} }
.noresultBox{background: #38383888; border-radius: 15px;padding: 30px; box-shadow: 1px 2px 5px 0px rgb(0 0 0 / 75%);}
.zeroResult{text-align: center; font-size:30px; font-weight: 500; color: #FFF;}
.zeroResult img{width: 80px; filter: invert();display: block; margin: auto; margin-bottom: 25px;}

.zeroResult{ display: none;}
.hideZero { display: block;}





.recommendedSection{background: linear-gradient(156.38deg, #3C0104 26.34%, #230103 95.91%);}
.recommendedSection .swiper{ padding: 70px 0px 0px 0px;}
.recommendedSection .swiper-button-prev{ left: 91%;  top: 26px; padding: 23px !important;}
.recommendedSection .swiper-button-next{ top: 26px; padding: 23px !important;}
.recommendedSection .swiper-button-prev:after, .recommendedSection .swiper-button-next:after { font-size: 16px; }
.recommendedSection{background: linear-gradient(156.38deg, #3C0104 26.34%, #230103 95.91%); padding: 30px 0px;}
.sliderBox{background: linear-gradient(156.38deg, #640207 15.22%, #4B0206 84.78%); border-radius: 3px; color: #FFF; padding: 15px;}
.sliderBox h3{text-align: center; font-size: 18px; margin-top: 15px; margin-bottom: 0px;     display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;}
.recommendedSectionTitle{color: #FFF; position:absolute; font-size: 24px;     margin-top: 15px;}
@media only screen and (max-width : 600px) {
    .recommendedSectionTitle{ font-size: 18px;  }
    .swiper-button-next, .swiper-button-prev{    top: 50% !important;}
    .recommendedSection .swiper-button-prev{    left: 1px !important;}
    .recommendedSection .swiper-button-next{right: 0px !important;}
}