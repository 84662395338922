.servicePages{
    background: url(../../assets/solutions-bg.jpg) !important;
    padding: 150px 0px;
    p{
        font-family: 'Michroma', sans-serif;
        font-weight: bold;
    }
    h2{
        color: #0FE2FF;
        font-size: 52px;
        font-weight: 600;
        margin-bottom: 30px;
    }
    button{
        margin-top: 25px;
        background-color: #00C1DB;
        color: #FFF;
        border:none;
        padding:10px 25px;
        text-transform: uppercase;
        border:2px solid #00C1DB;
        font-weight: 500;
        &:hover{
            background-color: #FFF;
            color: #00C1DB;
        }
    }
}


.processBox{
    background-color: #EBF5FC;
    border-radius: 10px;
    padding: 25px;
    p{
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 28px !important;
    }
    li{
        padding:5px 0px;
        font-family: 'Michroma', sans-serif;
        font-size: 14px;
    }
}

@media only screen and (max-width :600px) {
    .solutionsPage {
        padding: 43px 0px;
        background-size: cover;
    }
    .solutionsPage p {
          line-height: 27px;
         font-size: 13px !important;
    }
    .solutionsPage h2{
        font-size: 28px !important;
    }
}