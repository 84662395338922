.footer{  
     background: #FFF;  padding: 50px 0px;}
.subscribeInput{background-color: #E9EFF6; border-radius: 50px; border:none; padding:10px; width: 80%;}
@media screen and (max-width: 600px) { 
     .subscribeInput{width: 100%;}

}
ul{padding: 0px; list-style: none;}
 ul li{  font-family: 'Michroma', sans-serif; font-size: 14px;}
a{color: #000; margin-bottom: 10px; display: block;}
a:hover{color: #00C1DB ;}
.mailtoLink{
     font-size: 17px !important;
}
.footer h3{ font-style: normal; font-weight: bold; font-size: 18px; color: #000; line-height: 22px; margin-bottom: 30px;}
.socialMedia{display: flex;}
.socialMedia a{margin-right: 15px;}
.copyrightFooter{background: #0F0001; color: #FFF; padding:15px 0px}
.copyrightFooter p{padding: 0px  !important; margin: 0px  !important;}
.footerLogo{width: 150px;}

