.solutionsPage{
    background: url(../../assets/aboutBg.jpg) !important;
    padding: 150px 0px;
    background-size: 100% !important;
    h2{
        color: #0FE2FF;
        font-size: 48px;
        font-weight: bold;
    }
    p{
        font-family: 'Michroma', sans-serif;
        font-weight: bold;
        line-height: 30px;
        color: #FFF;
    }
    button{
        background-color: #0FE2FF;
        color: #FFF;
        border:none;
        padding: 10px 25px;
    }
}