.aboutPage{
    background: url(../../assets/solutions-bg.jpg) !important;
    padding: 50px 0px;
    .aboutBox{
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
        padding: 20px;
        background-color: #FFF;
    }
}

.teambox{
    margin-bottom: 15px;
    .teamname{
        font-size: 1.4rem;
        text-align: center;
        margin-top: 15px;
        @media only screen and (max-width :576px) {
            font-size: 1rem;
        }
    }
    .teampost{
        font-size: 1rem;
        text-align: center;
        @media only screen and (max-width :576px) {
            font-size: 0.8rem;
        }
    }
}
