.faqpage{
    background-color: #FFF;
    padding: 60px 0px;
}
.faqTitle{
    background: url(../../assets/faqpage.jpg);
    padding: 25px;
}
.link{
    color: #00C1DB;
    display: inline-block;
}
.react-tabs__tab-list{
    background: #E6E6E6;
    border-radius: 5px;
    border-bottom:none;
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    color: var(--color-four);
    width: 24%;
    color: #FFF;
    margin-left: 38%;
}
@media only screen and (max-width : 1025px) {
    .react-tabs__tab-list{
        width: 95%;
        margin-left: 0%;
    }
    .accordion{
        width: 100% !important;
    }
}
.react-tabs__tab{
    border:none
}
.react-tabs__tab--selected{
    background: var(--gradient-one);
    border-radius: 5px !important;
    color: #C99C9C;
}

.couldnotfind{
    font-size: 20px;
    margin-top: 10px;
}
.accordion{
    width: 100%;
    display: block;
    margin: auto;
    border: 0px solid rgba(0, 0, 0, 0.1) !important;
}
@media only screen and (max-width : 768px) {
   
    .accordion{
        width: 100% !important;
        display: block;
        margin: auto;
    }
}
.accordion__button{
    background: #E6E6E6;
    border-radius: 5px;
    color: #000 !important;
    margin-top: 15px;
    width: initial !important;
}
.accordion__panel{
    background: #fff;
    border-radius: 5px;
    color: #000;
    font-size: 15px !important;
    line-height: 26px;
}

