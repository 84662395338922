.contactGrid{
    display: grid;
    grid-template-columns: 1fr 0.7fr;
    width: 95%;
    margin:auto;
    grid-gap:10px;
    padding: 15px;
    background-size:100%;
    background-repeat: no-repeat;
    border-radius: 16px;
}
.contactPage{
    padding: 60px 0px;
}
.contactPageP{
    font-size: 19px;
    margin:25px 0px;
    a{
        color: #0FE2FF;

    }
}
.blurGridBox{
    backdrop-filter: blur(30px);
    background: rgba(66, 66, 66, 0.1);
}
.contactGridImage{ width: 100%; height: 100%; object-fit: cover; border-radius: 10px;}
@media only screen and (max-width : 1440px) {
    .contactGrid{ width: 100%; }
}
@media only screen and (max-width : 1200px) {
    .contactGrid{width: 100%; grid-template-columns: 1fr ;  grid-gap: 20px;  padding: 0px; }
}

@media only screen and (max-width : 600px) {
    .contactGrid{width: 100%; grid-template-columns: 1fr ;    padding: 0px; }
}












