.bannerSection{
  background: url(../../assets/hero.jpg);
  background-size: cover;
  background-position: center;
  padding:180px 0px;
  height: 863px;
  display: grid;
  align-items: center;
  margin-top: -79px;
  button{
    background-color: #0FE2FF;
    padding:10px 20px;
    color: #FFF;
    text-transform: uppercase;
    border:none;
  }
  h2{
    color: #2B4C64;
    font-weight: 600;
    font-size: 37px;
    font-family: 'Montserrat', sans-serif;

    span{
      color: #00C1DB;
    }
  }
  p{
    font-size: 17px;
    font-family: 'Michroma', sans-serif;
    font-weight: bold;
  }
}

@media only screen and (max-width :600px) {
  .bannerSection{
    background: url(../../assets/hero_mobile.jpg);
    background-position: center;
    background-size: cover;
    padding: 98px 0px;
    align-items: initial;
    h2{
      font-size: 24px;

    }
    p{
      font-size: 14px;
      font-family: 'Michroma', sans-serif;
      font-weight: bold;
    }
  }

}
.sectionOne{
  background: url(../../assets/solutions-bg.png);
  padding: 90px 0px;
  background-attachment: fixed;

  .card-front{
    h2{
      display: block;
    }
  }
  .cardRowLeft{margin-top: 150px;}
  .cardRowRight{margin-top: 150px;}
  h2{
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 48px;
    color: #2B4C64;
    line-height: 70px;
    margin-bottom: 50px;
  }
  .itemBox{
    background-color: #FFF;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.07);

  }
  .cardRow{
    margin-bottom: 25px;
  }
}


@media only screen and (max-width :600px) {
  .sectionOne{
    .cardRowLeft{margin-top: 0px;}
    .cardRowRight{margin-top: 0px;}

    h2{
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 24px;
    }
    .itemBox{
      background-color: #FFF;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.07);
  
    }
    .cardRow{
      margin-bottom: 0px;
    }
  }
  

}



.sectionTwo{
  padding: 80px 0px;
  h2{
    text-transform: uppercase; 
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 28px;
  }
  p{
    font-family: 'Michroma', sans-serif;
    font-size: bold;
  }
}



.cardRowLeft{
  margin-top: 191px;
}
.cardRowRight{
  margin-top: 191px;

}
.card-wrap{
  width: 100%;
  border-radius: 0.37rem;
  perspective: 1000px;
  height: 280px;
  margin-bottom: 30px;
}
.card{
  height: inherit;
  width: inherit;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d; 
  border:none !important
}
.card-back{
  background-color: #00C1DB;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg) !important;
  padding: 25px;
  text-align: center;
  color: #FFF;
  p{
    font-family: 'Montserrat', sans-serif !important;
    font-size: 15px;
    font-weight: 400 !important;
  }
}

.card-front,.card-back{
  position: absolute;
  height: inherit;
  width: inherit;
  backface-visibility: hidden;
  border-radius: 0.37rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.07);
  img{
    max-width: 110px;
  }
  a{
    color: #FFF;
    font-weight: bold;
    &:hover{
      color: #FFF;
    }
  }
}
.card-front{
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  img{
    display: block;
    margin: auto;
  }
  h4{
    text-align: center;
    color: #00C1DB;
  }
}
.card-wrap:hover .card{
  -webkit-transform: rotateY(180deg) !important;
  transform: rotateY(180deg);
}
@media only screen and (max-width :600px) {
  .card-wrap:hover .card{
    -webkit-transform: initial !important;
    transform: initial;
  }
}
























#tsparticles {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}





@media only screen and (min-width :600px) {
    .mobileSlider{display:none    }
}
.mobileSlider .rec-dot_active{
    background: var(--color-one);
}
.mobileSlider .rec-carousel-wrapper{
    margin: 0px !important;
    z-index: 9999;
}
.mobileSlider .rec-slider-container{
    margin:0px !important
}
.mobileSlider .rec-pagination{
    margin-top: -52px;
    z-index: 999;
}
.mobileSlider img{
    width: 100vw !important;
}
.mobileSlider .rec-dot {
    box-shadow: 0 0 1px 2px rgb(255 255 255 / 100%);
}
.mobileSlider .rec-arrow-right, .mobileSlider .rec-arrow-left{
    margin-top: 0px !important;
    background: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
.mobileSlider .rec-arrow-left{
    left: 0px;
    z-index: 9999;
}
.mobileSlider .rec-arrow-right{
    right: 0px;
}
.homepageComponent{
    display: grid;
    align-items: center;
    grid-template-columns: 1.3fr 1fr 420px;
    padding:35px;
    background-size: cover;
}

.mobileHighRoller{
    display: none;
}

@media only screen and (max-width : 1025px) {
    .homepageComponent{grid-template-columns: 1fr 10px 1fr; height: auto!important;}
}

@media only screen and (max-width : 768px) {
    .homepageComponent{grid-template-columns: 1fr; height: auto !important;}
    
}

@media only screen and (max-width : 460px) {
    .blurryText{
        display: none;
    }

    .homepageComponent{
        padding: 30px 15px;
        margin-top:-5px;
    }

    .mobileHighRoller{
        display: block;
        /* color: #FFF;
        font-size: 25px; */
    }
    .hero-text{
      margin-bottom: 16px;
    }
}

.homepageDescription{
  line-height: 29px;
  animation: fadein 3s ease-in forwards;
}

.blurryText{
  background: rgba(51, 51, 51, 0.1);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  color: var(--color-two);
  padding: 30px;
  border-radius: 10px;
  animation: fadein 1.5s ease-in forwards;
}

@keyframes fadein {
  to {
    transform: scale(.125);
  }
}


.blurryText h3{
    font-size: 36px;
    margin:0px;
    color: var(--color-four);
}
@media only screen and (max-width : 1440px) {
    .blurryText{
        padding: 15px;

    }
}
@media only screen and (max-width : 992px) {
    .blurryText {
        padding-right: 15px;
        margin-bottom: 15px;
    }
    .blurryText h3{font-size: 20px;}
}
.blurryText p{
    font-size: 16px;
line-height: 30px;
}





























.hidee{display: none;}
.holaaa{ height: 270px; }
.showholaaaa{ opacity: 1 !important;  transition:  1s ease-in-out;}
.hideholaaaa{ opacity: 0 !important;  transition:  1s ease-in-out;}
#uniquepointImg{height: 275px !important; display: flex; align-items: center; justify-content: center; img{border-radius: 50px;} }
#uniquepointImgThree{height: 275px !important;
  display: flex;
  align-items: center;
  justify-content: center; img{width: 75%;}}
#uniquepointImgTwo{    position: absolute; top: 0; height: 275px !important; display: flex; align-items: center; justify-content: center; width: 100%; img{width: 200px !important;}}
html {  scroll-behavior: smooth; }
.bgg{  background: url(../../assets/solutions-bg.png); background-attachment: fixed;}
.holaaa{  transition: transform 1s ease-in-out;}








.featureRow{
  align-items: center;
  .featureLeft{
    color:#009;
    text-align: center;
  }
  .featureRight{
    border-left: 4px solid #0FE2FF;
    padding: 0px 15px;
    color: #009;
    h3{font-size:18px;}
  }
}

$aligncorrect: 0px;
.iconTransform{height: 100px;  transition: transform 1s ease-in-out; img{ width: 75px !important; }}
// .iconTransformOne{ transition: transform 1s ease-in-out;transform: translate(518px, calc(-840px - #{$aligncorrect})); img{ width: 57px;}}
// .iconTransformTwo{ transition: transform 1s ease-in-out;transform:translate(168px, calc(-732px -  #{$aligncorrect})); img{width: 47px;}}
// .iconTransformThree{ transition: transform 1s ease-in-out;transform: translate(28px, calc(-759px - #{$aligncorrect})); img{width: 47px;}}
// .iconTransformFour{ transition: transform 1s ease-in-out;transform: translate(-328px, calc(-652px - #{$aligncorrect})); img{ width: 67px;}}
// .iconTransformFive{ transition: transform 1s ease-in-out;transform: translate(390px, calc(-917px  - #{$aligncorrect})); img{ width: 77px;}}
// .iconTransformSix{ transition: transform 1s ease-in-out;transform: translate(377px, calc(-929px - #{$aligncorrect})); img{ width: 77px;}}
// .iconTransformSeven{ transition: transform 1s ease-in-out;transform: translate(23px, calc(-832px - #{$aligncorrect})); img{width: 67px;}}
// .iconTransformEight{ transition: transform 1s ease-in-out;transform: translate(-377px, calc(-806px - #{$aligncorrect}));img{ width: 47px;}}
 
  .iconTransformOne{ transition: transform 1s ease-in-out;transform: translate(157%, calc(-305% - #{$aligncorrect})); img{ width: 57px;}}
  .iconTransformTwo{ transition: transform 1s ease-in-out;transform: translate(28%, calc(-267% -  #{$aligncorrect})); img{width: 47px;} #opera{    position: relative;  left: -4%;}}
  .iconTransformThree{ transition: transform 1s ease-in-out;transform:  translate(-35%, calc(-276% - #{$aligncorrect})); img{width: 47px;} #opera{    position: relative;  left: 4%;}}
  .iconTransformFour{ transition: transform 1s ease-in-out;transform: translate(-159%, calc(-238% - #{$aligncorrect})); img{ width: 67px;}}
  .iconTransformFive{ transition: transform 1s ease-in-out;  transform: translate(122%, calc(-333% - #{$aligncorrect}));  img{ width: 77px;}  #opera{    position: relative;  left: -7%;}}

  .iconTransformSix{ transition: transform 1s ease-in-out;transform: translate(41%, calc(-293% -  #{$aligncorrect})); img{ width: 77px;}#opera{    position: relative;    left: -2%;}}
  .iconTransformSeven{ transition: transform 1s ease-in-out;transform: translate(-33%, calc(-303%  - #{$aligncorrect})); img{width: 67px;} #opera{    position: relative;  left: 5%;}}
  .iconTransformEight{ transition: transform 1s ease-in-out;transform: translate(-121%, calc(-338% - #{$aligncorrect}));img{ width: 47px;} #opera{    position: relative;  left: 7%;}}
@media only screen and (max-width :1916px) {
  .iconTransform{width: 250px;  transition: transform 1s ease-in-out;}
  .iconTransformOne{ transition: transform 1s ease-in-out;transform: translate(419px, calc(-305% - #{$aligncorrect})); img{ width: 57px;}}
  .iconTransformTwo{ transition: transform 1s ease-in-out;transform: translate(59px, calc(-267% -  #{$aligncorrect})); img{width: 47px;} #opera{    position: relative;  left: -4%;}}
  .iconTransformThree{ transition: transform 1s ease-in-out;transform:  translate(-81px, calc(-276% - #{$aligncorrect})); img{width: 47px;} #opera{    position: relative;  left: 4%;}}
  .iconTransformFour{ transition: transform 1s ease-in-out;transform: translate(-424px, calc(-238% - #{$aligncorrect})); img{ width: 67px;}}
  .iconTransformFive{ transition: transform 1s ease-in-out;  transform: translate(300px, calc(-333% - #{$aligncorrect}));  img{ width: 77px;}  #opera{    position: relative;  left: -7%;}}
  .iconTransformSix{ transition: transform 1s ease-in-out;transform: translate(100px, calc(-293% -  #{$aligncorrect})); img{ width: 77px;}#opera{    position: relative;    left: -2%;}}
  .iconTransformSeven{ transition: transform 1s ease-in-out;transform: translate(-74px, calc(-303%  - #{$aligncorrect})); img{width: 67px;} #opera{    position: relative;  left: 5%;}}
  .iconTransformEight{ transition: transform 1s ease-in-out;transform: translate(-300px, calc(-338% - #{$aligncorrect}));img{ width: 47px;} #opera{    position: relative;  left: 7%;}}
}
@media only screen and (max-width :1200px) {
  .iconTransform{width: 250px;  transition: transform 1s ease-in-out;}
  .iconTransformOne{ transition: transform 1s ease-in-out;transform: translate(428px, calc(-840px - #{$aligncorrect})); width: 57px;}
  .iconTransformTwo{ transition: transform 1s ease-in-out;transform:translate(122px, calc(-732px -  #{$aligncorrect})); width: 47px;}
  .iconTransformThree{ transition: transform 1s ease-in-out;transform: translate(28px, calc(-759px - #{$aligncorrect})); width: 47px;}
  .iconTransformFour{ transition: transform 1s ease-in-out;transform: translate(-284px, calc(-652px - #{$aligncorrect})); width: 67px;}
  .iconTransformFive{ transition: transform 1s ease-in-out;transform: translate(302px, calc(-935px  - #{$aligncorrect})); width: 77px;}
  .iconTransformSix{ transition: transform 1s ease-in-out;transform: translate(330px, calc(-954px - #{$aligncorrect})); width: 77px;}
  .iconTransformSeven{ transition: transform 1s ease-in-out;transform: translate( 23px, calc(-856px -  #{$aligncorrect})); width: 67px;}
  .iconTransformEight{ transition: transform 1s ease-in-out;transform:translate(-333px, calc(-828px  - #{$aligncorrect})); width: 47px;}
}
@media only screen and (max-width :992px) {
  .iconTransform{width: 250px;  transition: transform 1s ease-in-out;}
  .iconTransformOne{ transition: transform 1s ease-in-out;transform: translate(313px, -844px); width: 47px;}
  .iconTransformTwo{ transition: transform 1s ease-in-out;transform: translate(0, -736px); width: 47px;}
  .iconTransformThree{ transition: transform 1s ease-in-out;transform: translate(-98px, -759px); width: 47px;}
  .iconTransformFour{ transition: transform 1s ease-in-out;transform: translate(187px, -949px); width: 67px;}
  .iconTransformFive{ transition: transform 1s ease-in-out;transform: translate(84px, -1019px); width: 77px;}
  .iconTransformSix{ transition: transform 1s ease-in-out;transform: translate(-38px, -969px); width: 77px;}
  .iconTransformSeven{ transition: transform 1s ease-in-out;transform: translate(283px, -1192px); width: 67px;}
  .iconTransformEight{ transition: transform 1s ease-in-out;transform:translate(157px, -1219px); width: 47px;}  
}
@media only screen and (max-width :768px) {

  .cardRowRight, .cardRowLeft{margin-top: 0px !important;}
  .serviceContainer h2{font-size: 28px !important; line-height: 40px !important; padding: 0px !important;}
  .iconTransformOne{ transition: transform 1s ease-in-out;transform: translate(223px, -840px); width: 47px;}
  .iconTransformTwo{ transition: transform 1s ease-in-out;transform: translate(-31px, -732px); width: 47px;}
  .iconTransformThree{ transition: transform 1s ease-in-out;transform: translate(-68px, -756px); width: 47px;}
  .iconTransformFour{ transition: transform 1s ease-in-out;transform:  translate(250px, -1017px); width: 67px;}
  .iconTransformFive{ transition: transform 1s ease-in-out;transform: translate(181px, -973px); width: 77px;}
  .iconTransformSix{ transition: transform 1s ease-in-out;transform: translate(-288px, -958px); width: 77px;}
  .iconTransformSeven{ transition: transform 1s ease-in-out;transform: translate(190px, -1213px); width: 67px;}
  .iconTransformEight{ transition: transform 1s ease-in-out;transform:  translate(127px, -1244px); width: 47px;}  

}
 
@media only screen and (max-width :576px) {
  .holaaa{height: initial !important;}
  .cardRowRight, .cardRowLeft{margin-top: 0px !important;}
  .featureRow .featureRight{text-align: center;}
  .serviceContainer h2{font-size: 28px !important; line-height: 40px !important; padding: 0px !important;}
  // .iconTransformOne{display: block; margin: auto; transition: transform 1s ease-in-out;transform: translate(125px, -840px); width: 47px;}
  // .iconTransformTwo{ display: block; margin: auto;  transition: transform 1s ease-in-out;transform: translate(-78px, -761px); width: 47px;}
  // .iconTransformThree{ display: block; margin: auto;  transition: transform 1s ease-in-out;transform: translate(63px, -1099px); width: 47px;}
  // .iconTransformFour{display: block; margin: auto;  transition: transform 1s ease-in-out;transform: translate(-142px, -1019px); width: 67px;}
  // .iconTransformFive{display: block; margin: auto;  transition: transform 1s ease-in-out;transform: translate(9px, -1301px); width: 77px;}
  // .iconTransformSix{display: block; margin: auto;  transition: transform 1s ease-in-out;transform: translate(-12px, -1318px); width: 77px;}
  // .iconTransformSeven{display: block; margin: auto;  transition: transform 1s ease-in-out;transform: translate(102px, -1542px); width: 67px;}
  // .iconTransformEight{display: block; margin: auto;  transition: transform 1s ease-in-out;transform: translate(-77px, -1567px); width: 47px;}
  .iconTransform{width: 100%;}
  .iconTransformOne{display: block; margin: auto;   width: 47px; transition: transform 1s ease-in-out;transform: translate(0px, 0px);}
  .iconTransformTwo{ display: block; margin: auto;   width: 47px;  transition: transform 1s ease-in-out;transform: translate(0px, 0px);}
  .iconTransformThree{ display: block; margin: auto;  width: 47px;  transition: transform 1s ease-in-out;transform: translate(0px, 0px);}
  .iconTransformFour{display: block; margin: auto;    width: 67px;  transition: transform 1s ease-in-out;transform: translate(0px, 0px);}
  .iconTransformFive{display: block; margin: auto;    width: 77px;  transition: transform 1s ease-in-out;transform: translate(0px, 0px);}
  .iconTransformSix{display: block; margin: auto;    width: 77px;  transition: transform 1s ease-in-out;transform: translate(0px, 0px);}
  .iconTransformSeven{display: block; margin: auto;    width: 67px;  transition: transform 1s ease-in-out;transform: translate(0px, 0px);}
  .iconTransformEight{display: block; margin: auto;   width: 47px;  transition: transform 1s ease-in-out;transform: translate(0px, 0px);}    
  .bulbContainer{display: none !important;}
  .hideholaaaa{opacity: 1 !important; }
  .bulbImage{display: none !important;}
  .featureRow .text-right{text-align: center !important;}
  .serviceContainer{padding: 0px !important;}
  .bulbContainerLol{padding: 0px !important}

  #uniquepointImgTwo{  width: 90%; }
  .sectionOne h2{ line-height: 37px; }
  
}


// @media only screen and (max-width :992px) {
//   .bulbContainer{height: auto !important;}
//   .cardRowRight, .cardRowLeft{margin-top: 0px !important;}
//   .serviceContainer h2{font-size: 28px !important; line-height: 40px !important; padding: 0px !important;}
//   .imageTransform{   transition: transform 1s ease-in-out; transform:translate(-23px, -610px);   width: 22px; }
//   .imageTransformTwo{     transition: transform 1s ease-in-out; transform: translate(292px, -1255px); width: 47px; }
//   .imageTransformThree{  transition: transform 1s ease-in-out;    transform: translate(245px, -1419px);   width: 44px; }
//   .imageTransformFour{  transition: transform 1s ease-in-out;     transform: translate(17px, -990px);   width: 43px; }
//   .imageTransformFive{  transition: transform 1s ease-in-out;transform: translate(-44px, -1126px);   width: 44px; }
//   .imageTransformSix{  transition: transform 1s ease-in-out;     transform: translate(72px, -1463px);  width: 44px; } 
//   .imageTransformSeven{  transition: transform 1s ease-in-out;transform: translate(-309px, -1285px);  width: 48px; }
//   .imageTransformEight{  transition: transform 1s ease-in-out;     transform: translate(-239px, -1434px);   width: 45px; }
  
// }
// .imageTransform{   transition: transform 1s ease-in-out; transform: translate(262px, -735px);   width: 18px; }
// .imageTransformTwo{   transition: transform 1s ease-in-out; transform:translate(6px, -773px);   width: 18px;}
// .imageTransformThree{  transition: transform 1s ease-in-out;  transform: translate(-268px, -734px); width: 18px; }

.stop-scrolling {   height: 100%;  overflow: hidden;}


.bulbContainer{
  // background: red;

  background: url(../../assets/solutions-bg.png);
  background-attachment: fixed;
  // height: 80vh;
  position: relative;
  display: grid;
  align-items: center;
  // img{
  //   width: 388px;
  //   position: sticky;
  //   z-index: 9999999;
  //   display: block !important;
  //   top: 0px;
  //   left: calc(50% - 194px);
  // }
}
.serviceContainer{
  background: url(../../assets/solutions-bg.png);
  padding: 100px 0px;
  background-attachment: fixed;

  h2{
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 48px;
    color: #2B4C64;
    line-height: 70px;
    margin-bottom: 50px;
  }
}


.hideServiceBox{
  transition:ease-in-out 1s;

  .card{background-color: transparent;}
  .card-front  {
    background-color: transparent !important;
    box-shadow: none;
    h4{
      visibility: hidden;
    }
  }
}
.showServiceBox{
  transition: ease-in-out 1s;
  .card{background-color: #FFF;}
  .card-front  {
    background-color: #FFF !important;
    box-shadow: 0px 6px 30px rgb(0 0 0 / 7%);
    h4{
      visibility: visible;
    }
  }
}

.hideOverflow{ overflow: hidden;}



@media only screen and (max-width :576px) {
  .sectionOne h2{
    line-height: 32px;
  }
  .rowBox{margin:0px !important}
}