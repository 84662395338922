/* .formHeading{ text-align: center; letter-spacing: 0.2em; font-size: 24px; margin: 0px; margin-bottom: 20px; color: var(--color-two);} */
.outlinebtn{
    border:1px solid var(--color-one); 
    background: transparent;
    color: var(--color-one);
    padding: 10px 20px;
    margin:auto;
    display: block;
    text-align: center;
    width: 50%;
}
 
.closeBtn{
    border: none;
    background: transparent;
    text-align: center;
    font-size: 16px;
    display: block;
    margin: auto;
    margin-top: 15px;
    text-transform: uppercase;

}

.modaloverlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.9);
    top: 0px;
    left: 0px;
    z-index: 9999;
}
.ReactModal__Content{
    z-index: 999999;
    position: relative;
}

.messageBox{
    border: 1px solid #FFF;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
}
.hideModal{
    display: none;
}
.formBoxx{
     
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset -4px -4px 20px rgba(255, 255, 255, 0.1), inset 4px 4px 20px rgba(0, 0, 0, 0.1); */
    /* backdrop-filter: blur(30px); */
    /* Note: backdrop-filter has minimal browser support */
    
    border-radius: 10px;
    padding:35px;
    color: #333;
    height: 100%;
}
.formBox  input, .formBox select, .formBox textarea{
    background: transparent;
    border:none;
    border-bottom:2px solid var(--color-two);
    box-sizing: border-box;
    border-radius: 0px;
    padding: 10px 0;
    margin-bottom: 15px;
    font-size: 15px;
    color:var(--color-two) ;
    margin-bottom: 25px;
}
.formBox  input::placeholder, .formBox select::placeholder, .formBox textarea::placeholder{
    color:var(--color-two) !important ;
}

.RadioBtn{display: grid; margin-top: 6px; margin-bottom: 15px;  grid-template-columns: 35px 1fr;}
.RadioBtn input{margin-bottom: 0px;}
.RadioBtn span{top: 5px; position: relative;}
.formBox textarea, .formBox  input{ width: 100%;}
.formBox  input::placeholder, .formBox select::placeholder, .formBox textarea::placeholder{ color: #F4EDFD;}

.messageSent{display: block !important; width: 130px; margin: auto !important;}

.formBox input[type="radio"]{width: 22px; height: 22px;}
.formBox  input:focus, .formBox select:focus, .formBox textarea:focus{ outline: none; border-bottom:2px solid var(--color-two);}
.InputOne{ border:1px solid #929292; width: 100%; }
.InputOne textarea{font-family: 'Roboto';}
.InputOne option{color: #333; cursor: pointer;}
.Twocol{ display: grid; grid-template-columns: 1fr 1fr; grid-gap: 10px;}
.SendBtn{text-transform: uppercase; width:100%; cursor: pointer; font-size: 16px; color:#fff ; padding:9px 0px; background: #00C1DB; border-radius: 4px; border:none; border:2px solid #00C1DB !important;   }
.SendBtn:hover{color: #00C1DB; background: #FFF; border:2px solid #00C1DB !important;}
.ResetBtn{ margin-bottom: 0px !important; border:1px solid transparent !important; background:transparent !important;  margin-bottom: 10px; cursor: pointer; font-size: 16px;  color:var(--color-three); padding:15px 30px; border: 1px solid var(--color-three); border-radius: 8px; background-color: transparent;}
.ResetBtn:hover{border:2px solid var(--color-two) !important; border-radius: 4px;}
.telephoneIcon{    width: 206px !important;  margin-bottom: 15px !important; display: block;  margin: auto;}

.RadioBtn input:after { width: 20px;  height: 20px;  border-radius: 15px;  top: 0;  left: 0; position: relative; background-color: #d1d3d1;   content: '';    display: inline-block; visibility: visible; border: 2px solid white;  }
.RadioBtn input:checked:after { width: 20px; height: 20px; border-radius: 15px;  top: 0; left: 0; position: relative;  background-color: #ffa500; content: ''; display: inline-block; visibility: visible;  border: 2px solid white;}

.formBoxx label{
    margin-bottom: 7px !important;
    margin-top: 15px;
}
.formBoxx input, .formBoxx textarea{
    border:1px solid #929292;
    padding:7px;
    border-radius: 3px;
}
@media only screen and (max-width : 460px) {.formBoxx{padding:15px}}
.showimp{ display: block !important;}
.hideimp{ display: none !important;}