.headerTwo{
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
    z-index: 9999;
    position: relative;
     .nav-link{
         color: #000 !important;
     }
}

.navbar-nav .dropdown-menu{
    margin-top: -5px;
}


.dropdown-item.active, .dropdown-item:active{
    background-color: #FFF;
    color: #000;
}