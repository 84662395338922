@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
body{font-family: 'Montserrat', sans-serif;}
:root {
  --color-one: #F90610;
  --color-two : #bd0000;
 }
 a{text-decoration: none;}
 a:hover{text-decoration: none;}
 @media screen and (min-width: 1920px) { .container { max-width: 1440px !important; } }
 .btn{ border:  1.5px solid #FFF; border-radius: 40px; background: transparent; color: #FFF; width: 170px; height: 50px;}
 /*.page{ background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%); padding: 50px 0px; }*/
 .page{
      /* background-image: linear-gradient(93.58deg,rgba(60, 1, 4, 0.3),rgba(25, 1, 2, .9) ), url(./assets/pagebg.jpeg); */
      background: linear-gradient(93.58deg, #3C0104 9.08%, #190102 100%);
      background-size: contain;
    background-position: center center;
    background-attachment: fixed;
    padding: 50px 0px;
  min-height: 60vh
 }


 .joinnowBtn{width: 100%;
    height: 40px;
   background: transparent;
    border: 1px solid #FFF;
    border-radius: 0px; margin-top: 10px;
    border-radius: 4px;color: #FFF;}  
  .joinnowBtn:hover{color: #FFF;  background: var(--color-two);}


  .ServiceHeading{
    color: #0FE2FF;
    font-size: 30px;
  }
  .ServiceList{
    list-style: disc;
    margin-left: 15px;
  }
  .ServiceList li{
    padding-bottom: 7px;
  }



  .serviceImg{
    display: none;
  }
  .serviceImgTwo{
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 997px) {
      .serviceImg{
        display: block;

      }
      .serviceImgTwo{
        display: none;
      }

  }





  .bulbAnimator{
    position: relative;
  }
  #showlol{
    display: block;
  }
  #hidelol{
    display: none;
  }