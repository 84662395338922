.header{
    
    .navbar{
        padding: 0px;
    }
    .navbar-brand{
        margin-right: 0px !important;
        margin-bottom: 0px !important;
    }
    // .navbar-collapse{
    //     border-top: 1px solid rgba(255,255,255,.5);
    //     margin-top: 10px;
    // }

    .navbar-light .navbar-toggler{
        background: #FFF;
        padding: 2px 4px;
    }
    
    .navbar-light .navbar-nav .nav-link{
        color: #FFF;
        margin-bottom: 0px !important;
        padding: 10px 24px;
        font-size: 16px;
    }
    .blueBtn{
        background-color: #00C1DB;
        color: #FFF !important;

        border-radius: 4px;
    }
}
#headerTwo a{
    color: #000 !important
}
@media screen and (max-width: 600px) { 
    .header .navbar-light .navbar-nav .nav-link{
        color:#000 !important;
    }
     .header .navbar-light .navbar-toggler{
        background: #FFF;
    padding: 2px 4px;
    z-index: 99999;
    position: relative;
    }
}