.termsandcondition{
    padding: 50px 0px;
}
.termsandcondition h3{
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 20px;
    
    font-family: 'Montserrat', sans-serif;

}