.privacypolicy{
    padding: 50px 0px;
}
.privacypolicy h3{
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 20px;
    
    font-family: 'Montserrat', sans-serif;

}
.privacypolicy h4, .privacypolicy h3{
    color: #00C1DB;
}
.privacyBox{box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
}